import React from 'react'
import {Row, Col} from 'react-bootstrap'
import styled from 'styled-components'
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

import CardGrid from '../card/card-grid'

import { InlineIcon } from '@iconify/react';

SwiperCore.use([Navigation]);

const RowTitle = styled.h1`
    font-size: 1.75rem;
`

const SubTitle = styled.span`
    font-family: ${({theme}) => theme.fonts.font2} !important;
`

const CarouselProducts = ({products, title, sub }) => {
    return(
            <Row className="my-4 px-2 px-md-3">
                <Col sm={12}>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div>
                            <RowTitle>{title}</RowTitle>
                            <SubTitle>{sub}</SubTitle>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="swiper-btn-prev me-2"><InlineIcon icon="bi:arrow-left-circle-fill" height="24px" width="24px" /></div>
                            <div className="swiper-btn-next"><InlineIcon icon="bi:arrow-right-circle-fill" height="24px" width="24px" /></div>
                        </div>
                    </div>
                    
  
                    <Swiper navigation={{"nextEl": '.swiper-btn-next', "prevEl": '.swiper-btn-prev'}}  pagination={{"clickable": true}} breakpoints={{
                        "320": {
                            "slidesPerView": 2,
                            "spaceBetween": 100
                        },
                        "768": {
                            "slidesPerView": 4,
                            "spaceBetween": 50
                        },
                        "1024": {
                            "slidesPerView": 6,
                            "spaceBetween": 30
                        }
                        }} className="mySwiper">
                        {products.slice(0,8).map((p, index) => (
                        <SwiperSlide key={index}>
                            <CardGrid product={p} key={p.id} eager={index === 0} />
                        </SwiperSlide>
                        ))}
                    </Swiper>
                </Col>
            </Row>
    )
}

export default CarouselProducts