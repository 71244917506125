import * as React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import { ProductListing } from "../components/product-listing"
import CarouselProducts from '../components/layout/carousel-products'
import CarouselProductsV from '../components/layout/columnCollection'
import SpecialRowCollections from '../components/layout/specialRowCollections'
import Row3Col from "../components/layout/row3cols"

export const query = graphql`
  query {
    attars: shopifyCollection(handle: { eq: "attars-collections" }) {
      products {
        ...ProductCard
      }
    }
    fkc: shopifyCollection(handle: {eq: "fk-creations"}){
      products{
        ...ProductCard
      }
    }
    astin: shopifyCollection(handle: {eq: "fkc-perfumes"}){
      products{
        ...ProductCardV
      }
    }
    masco: shopifyCollection(handle: {eq: "masco"}){
      products{
        ...ProductCardV
      }
    }
    alnuaim: shopifyCollection(handle: {eq: "al-nuaim"}){
      products{
        ...ProductCard
      }
    }
    arochem: shopifyCollection(handle: {eq: "arochem-attars"}){
      products{
        ...ProductCard
      }
    }
  }
`
export default function IndexPage({ data }) {
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <CarouselProducts products={data.attars.products} title="Fast Moving Attars" sub="Top 10 fast movieng attars (Roll On perfumes)" />
          <SpecialRowCollections />
          <CarouselProducts products={data.fkc.products} title="FKC Attars" sub="Top selling attars in FKC Brand" />
          <Row3Col>
            <CarouselProductsV products={data.astin.products} title="FKC" sub="Perfumes from FKC" />
            <CarouselProductsV products={data.masco.products} title="Masco" sub="Masco Boutique" />
          </Row3Col>
          
          <CarouselProducts products={data.alnuaim.products} title="Al-Nuaim Attars" sub="Top selling attars in Al-Nuaim Brand" />
          <CarouselProducts products={data.arochem.products} title="Arochem Perfumed Roll On Attars" sub="Top selling attars Arochem" />
        </Col>
      </Row>
    </Container>
  )
}
