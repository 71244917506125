import React from 'react'
import { Card } from 'react-bootstrap'

import { graphql } from "gatsby"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../../utils/format-price"

import {CardLink, CardStyledV, GatsbyImg, VendorName, ProductTitleV, PriceV} from './card-grid.styled'

const CardRowGrid = ({product, eager}) => {

    const { title, priceRangeV2, slug, images: [firstImage], vendor, storefrontImages } = product
    
      const price = formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        priceRangeV2.minVariantPrice.amount
      )
    
      let storefrontImageData = {}
      if (storefrontImages) {
        const storefrontImage = storefrontImages.edges[0].node
        try {
          storefrontImageData = getShopifyImage({
            image: storefrontImage,
            layout: "fixed",
            width: 200,
            height: 200,
          })
        } catch (e) {
          console.error(e)
        }
      }

    return(
      <CardLink to={slug} className="mb-3" aria-label={`View ${title} product page`}>
          <CardStyledV className="d-flex flex-row justify-content-center" heightrem="10rem"> 
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <GatsbyImg
                  className="ms-1"
                  alt={firstImage?.altText ?? title}
                  image={firstImage?.gatsbyImageData ?? storefrontImageData}
                  loading={eager ? "eager" : "lazy"}
                  />
              <Card.Body className="py-2">
                  <div className="d-flex flex-column">
                    <VendorName>{vendor}</VendorName>
                    <ProductTitleV row as={"span"}>{title}</ProductTitleV>
                  </div>
                  <PriceV>{price}</PriceV>
                  {/* <AddtoCart>Add to Cart</AddtoCart> */}
              </Card.Body>
          </CardStyledV> 
      </CardLink>
    )
}

export const query = graphql`
  fragment ProductCardV on ShopifyProduct {
    id
    title
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    images {
      id
      altText
      gatsbyImageData(
        aspectRatio: 1
        width: 200,
        height: 200
        )
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    vendor
  }
`

export default CardRowGrid