import styled from 'styled-components'
import {Card, Row} from 'react-bootstrap'
import GatsbyLink from 'gatsby-link';

export const BgGradientRow = styled(Row)`
    background: ${props => props.bg ? props.bg : ({theme}) => theme.cardBg} !important;
    height: auto;
    border-radius: 15px;
`;

export const RowTitle = styled.h1`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: ${props => props.color ? props.color : ({theme}) => theme.body};
    margin-bottom: 1.5em;
`;

export const GatsbyLinkStyled = styled(GatsbyLink)`
    color: ${({theme}) => theme.text};

    &:hover{
        color: ${({theme}) => theme.accent.primary.color}
    }

`

export const CardItem = styled(Card)`
    background: ${({theme}) => theme.cardBg};
    padding-top: 2em;
    border-radius: 10px;
    text-align: center;

    &:hover{
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    }
`;