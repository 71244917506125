import React from 'react'
import {Row, Col} from 'react-bootstrap'
import styled from 'styled-components'
import CardRowGrid from '../card/card-row-grid'


const RowTitle = styled.h1`
    font-size: 1.75rem;
`

const SubTitle = styled.span`
    font-family: ${({theme}) => theme.fonts.font2} !important;
`

const CarouselProductsV = ({products, title, sub}) => {
    return(
        <Col sm={12} md={6}>
            <div className="mb-3">
                <div className="mb-4">
                    <RowTitle>{title}</RowTitle>
                    <SubTitle>{sub}</SubTitle>
                </div>
                
                <Row xs={1} md={2} className="gy-3">
                    {products.slice(0,6).map((p, index) => (
                        <Col key={index}>
                            <CardRowGrid product={p} key={p.id} eager={index === 0} />
                        </Col>
                    ))}
                </Row>
                    
            </div>
        </Col>
    )
}

export default CarouselProductsV