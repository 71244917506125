import React from 'react'
import { Row } from 'react-bootstrap'

const Row3Col = ({children}) => {
    return(
        <Row className="my-4 px-3">
            {children}
        </Row>
    )
}

export default Row3Col 