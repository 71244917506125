import React from 'react';
import { Card, Col } from 'react-bootstrap';
import {BgGradientRow, RowTitle, GatsbyLinkStyled, CardItem} from './specialRowCollections.styled'

const SpecialRowCollections = () => {
    return(
        <BgGradientRow className="mx-3 px-3 py-5" bg={"linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);"}>
            <RowTitle color={"#000000"}>Varieties of Attars</RowTitle>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/flowers`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Floral
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/musk`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Musk
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/oudh`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Oudh
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/herbal`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Herbal
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/warm-attars`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Warm Attars
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/cool-attars`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Cool Attars
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/jawadhu`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Jawadhu
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>         
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/sandal`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Sandal
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>     
            <Col className="my-2">
                <GatsbyLinkStyled to={`/collections/jasmine`}>
                    <CardItem className="h-100">
                        <Card.Body>
                            Jasmine
                        </Card.Body>
                    </CardItem>
                </GatsbyLinkStyled>
            </Col>  
        </BgGradientRow>
    )
}

export default SpecialRowCollections